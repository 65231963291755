


































































































































































































































































// core
import Vue from "vue";
import moment, { Moment } from "moment";
import printJS from "print-js";
// service
import { salesReturnServices } from "@service/sales-return.service";
import { contactServices } from "@service/contact.service";
// interface
import { ResponseListSalesReturn, ResponseSalesReturn } from "@interface/sales-return.interface";
import { ResponseListContactData } from "@interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
// constant
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { SALES_RETURN_STATUS } from "@enum/sales-return.enum";
// utilities
import { debounceProcess } from "@helper/debounce";
import { formatCurrency } from "@/validator/globalvalidator";
import { mapGetters } from "vuex";

interface IFormModel {
  customerName: string | undefined;
  returnNumber: string | undefined;
  journalNumber: string | undefined;
  date: Moment[] | null;
}

interface Row extends ResponseSalesReturn {
  key: any;
}

export default Vue.extend({
  name: "CustomerReturnList",
  components: {
    CSelectCustomer: () => import(/*webpackPrefetch: true*/"@/components/shared/select-customer/CSelectCustomer.vue"),
    CSelectJournalNumber: () => import(/*webpackPrefetch: true*/"@/components/shared/select-journal-number/CSelectJournalNumber.vue"),
  },
  data() {
    this.searchReturnNumber = debounceProcess(this.searchReturnNumber, 300);
    return {
      DEFAULT_DATE_FORMAT,
      SALES_RETURN_STATUS,
      formModel: {
        customerName: undefined as string | undefined,
        returnNumber: undefined as string | undefined,
        journalNumber: undefined as string | undefined,
        date: null as Moment[] | null
      } as IFormModel,
      formProps: {
        customerName: {
          label: "lbl_customer_name",
          placeholder: "lbl_choose"
        },
        returnNumber: {
          label: "lbl_return_number",
          placeholder: "lbl_choose"
        },
        soNumber: {
          label: "lbl_sales_order_number",
          placeholder: "lbl_choose"
        },
        journalNumber: {
          label: "lbl_journal_number",
          placeholder: "lbl_choose"
        },
        date: {
          label: "lbl_date",
          placeholder: "lbl_choose"
        },
      },
      dtListCustomerReturn: {} as ResponseListSalesReturn,
      dtListReturnNumber: {} as ResponseListSalesReturn,
      loading: {
        table: false,
        download: false,
        custName: false,
        returnNumb: false,
        journalNumb: false,
        print: false,
      },
      queryParams: {
        limit: 20,
        page: 0,
        sorts: "createdDate:desc"
      } as RequestQueryParamsModel,
      dtTable: [] as Row[]
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "sales-return" && x.privilege.update && x.privilege.create);
    },
    formWrapper() {
      return {
        labelCol: {
          sm: {
            span: 24
          },
          md: {
            span: 7
          }
        },
        wrapperCol: {
          sm: {
            span: 24
          },
          md: {
            span: 15
          }
        }
      };
    },
  },
  created() {
    this.fetchListCustomerReturn(this.queryParams, true);
  },
  methods: {
    moment,
    formatCurrency,
    getListCustomerReturn(params: RequestQueryParamsModel): Promise<ResponseListSalesReturn> {
      return salesReturnServices.getListSalesReturn(params);
    },
    downloadReport(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
      return salesReturnServices.downloadReport(params);
    },
    async fetchListCustomerReturn(params: RequestQueryParamsModel, flag = false): Promise<void> {
      try {
        this.loading.table = true;
        const res = await this.getListCustomerReturn(params);
        this.dtListCustomerReturn = res;
        this.dtTable = res.data.map(x => ({
          key: x.id,
          ...x
        }));
        if (flag) this.dtListReturnNumber = res;
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loading.table = false;
      }
    },
    constructSearchQuery(formModel: IFormModel): string {
      let searchBy: string[] = [];
      if (formModel.customerName) searchBy.push(`customer.secureId~${formModel.customerName}`);
      if (formModel.returnNumber) searchBy.push(`returnNumber~${formModel.returnNumber}`);
      if (formModel.journalNumber) searchBy.push(`generalJournal.secureId~${formModel.journalNumber}`);
      if (formModel.date && formModel.date.length) {
        const start = formModel.date[0].set({hour: 0, minute: 0, second: 0}).format();
        const end = formModel.date[1].set({hour: 23, minute: 59, second: 59}).format();
        searchBy.push(`returnDate>=${start}_AND_returnDate<=${end}`);
      }
      return searchBy.join("_AND_");
    },
    handleFilter(): void {
      const { formModel } = this;
      this.queryParams.search = this.constructSearchQuery(formModel);
      this.fetchListCustomerReturn(this.queryParams);
    },
    handleClear(): void {
      this.formModel = {
        customerName: undefined,
        returnNumber: undefined,
        journalNumber: undefined,
        date: null
      };
      this.queryParams.search = "";
    },
    goToCreate(): void {
      this.$router.push({ name: "sales.transactionsales.customerreturn.create" });
    },
    goToUpdate(id: string): void {
      this.$router.push({ name: "sales.transactionsales.customerreturn.edit", params: { id } });
    },
    goToDetail(id: string): void {
      this.$router.push({ name: "sales.transactionsales.customerreturn.detail", params: { id } });
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.queryParams.limit = response.size;
      this.queryParams.page = 0;
      this.fetchListCustomerReturn(this.queryParams);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.queryParams.page = response.page - 1;
      this.fetchListCustomerReturn(this.queryParams);
    },
    buildQueryDownload({ customerName: customerId, date, journalNumber: journalId, returnNumber, }: IFormModel): string {
      const queries: string[] = [];
      if (customerId) queries.push(`customerId~${customerId}`);
      if (date) {
        const [start, end] = date;
        const first = start.set({hour: 0, minute: 0, second: 0}).format();
        const last = end.set({hour: 23, minute: 59, second: 59}).format();
        queries.push(`returnDate>=${first}_AND_returnDate<=${last}`);
      }
      if (journalId) queries.push(`journalId~${journalId}`);
      if (returnNumber) queries.push(`returnNumber~${returnNumber}`);
      return queries.join("_AND_");
    },
    async handleDownload(): Promise<void> {
      try {
        this.loading.download = true;
        const params: RequestQueryParamsModel = {
          limit: this.dtListCustomerReturn.totalElements,
          page: 0,
          sorts: "createdDate:desc",
          search: this.buildQueryDownload(this.formModel) ?? undefined,
        };
        const arrb = await this.downloadReport(params);
        const url = window.URL.createObjectURL(new Blob([arrb]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Report_Customer_Return.xlsx"); //or any other extension
        link.click();
      } catch (error) {
        this.$message.error(this.$t("notif_download_error").toString());
      } finally {
        this.loading.download = false;
      }
    },
    async handlePrint(): Promise<void> {
      try {
        const params: RequestQueryParamsModel = {
          limit: this.dtListCustomerReturn.totalElements,
          page: 0,
          sorts: "createdDate:desc"
        };
        if (this.queryParams.search) params.search = this.queryParams.search;
        this.loading.print = true;
        const res = await this.getListCustomerReturn(params);
        const properties = [
          "returnNumber",
          "customerName",
          "returnDate",
          "returnTotalFormat",
          "journalNumber",
        ];
        res.data.forEach(x => {
          if (!x.returnNumber) x.returnNumber = "-";
          if (!x.customerName) x.customerName = "-";
          if (!x.returnDate) x.returnDate = "-";
          else x.returnDate = this.moment(x.returnDate).format(DEFAULT_DATE_FORMAT);
          if (!x.returnTotal) x["returnTotalFormat"] = formatCurrency("0");
          else x["returnTotalFormat"] = formatCurrency(x.returnTotal.toString());
          if (!x.journalNumber) x.journalNumber = "-";
        });
        printJS({
          printable: res.data,
          properties,
          type: "json",
          gridHeaderStyle: "border: 1px solid #000",
          gridStyle: "text-align: center;border: 1px solid #000",
          onError: (error) => {
            this.$notification.error({
              message: this.$t("lbl_error_title").toString(),
              description: error.message
            });
          }
        });
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loading.print = false;
      }
    },
    async searchReturnNumber(search = ""): Promise<void> {
      try {
        this.loading.returnNumb = true;
        const params: RequestQueryParamsModel = {
          limit: 10,
          page: 0,
          sorts: "createdDate:desc"
        };
        if (search) params.search = `returnNumber~*${search}*`;
        const res = await this.getListCustomerReturn(params);
        this.dtListReturnNumber = res;
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loading.returnNumb = false;
      }
    },
  }
});
