export enum SALES_RETURN_STATUS {
  CANCELLED = "Cancelled",
  DRAFT = "Draft",
  SUBMITTED = "Submitted"
}

export enum REFUND_OPTIONS {
  CREDIT_MEMO = "Credit Memo",
  REFUND = "Refund",
}

export enum RETURN_FROM {
  DELIVERY_ORDER = "Delivery Order",
  INVOICE = "Invoice",
}
