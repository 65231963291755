var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_customer_return") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-form-model",
                    _vm._b(
                      {
                        attrs: { model: _vm.formModel, "label-align": "left" }
                      },
                      "a-form-model",
                      _vm.formWrapper,
                      false
                    ),
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formProps.customerName.label
                                    )
                                  }
                                },
                                [
                                  _c("CSelectCustomer", {
                                    model: {
                                      value: _vm.formModel.customerName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "customerName",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.customerName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formProps.returnNumber.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          _vm.formProps.returnNumber.placeholder
                                        ),
                                        "show-search": "",
                                        "filter-option": false,
                                        loading: _vm.loading.returnNumb,
                                        "allow-clear": ""
                                      },
                                      on: { search: _vm.searchReturnNumber },
                                      model: {
                                        value: _vm.formModel.returnNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formModel,
                                            "returnNumber",
                                            $$v
                                          )
                                        },
                                        expression: "formModel.returnNumber"
                                      }
                                    },
                                    _vm._l(
                                      _vm.dtListReturnNumber.data,
                                      function(item, i) {
                                        return _c(
                                          "a-select-option",
                                          {
                                            key: i,
                                            attrs: { value: item.returnNumber }
                                          },
                                          [
                                            _c(
                                              "a-tooltip",
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "title" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.returnNumber
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.returnNumber) +
                                                    " "
                                                )
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formProps.journalNumber.label
                                    )
                                  }
                                },
                                [
                                  _c("CSelectJournalNumber", {
                                    model: {
                                      value: _vm.formModel.journalNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "journalNumber",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.journalNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formProps.date.label)
                                  }
                                },
                                [
                                  _c("a-range-picker", {
                                    attrs: {
                                      placeholder: [
                                        _vm.$t(_vm.formProps.date.placeholder),
                                        _vm.$t(_vm.formProps.date.placeholder)
                                      ],
                                      format: _vm.DEFAULT_DATE_FORMAT
                                    },
                                    model: {
                                      value: _vm.formModel.date,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formModel, "date", $$v)
                                      },
                                      expression: "formModel.date"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleClear } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.handleFilter }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 12, align: "end" } },
                [
                  _vm.hasPrivilegeCreate
                    ? _c(
                        "a-button",
                        {
                          attrs: { icon: "plus", type: "primary" },
                          on: { click: _vm.goToCreate }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 116] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-table",
                    {
                      attrs: {
                        "data-source": _vm.dtTable,
                        pagination: false,
                        loading: _vm.loading.table,
                        scroll: { x: "calc(300px + 50%)", y: 520 }
                      }
                    },
                    [
                      _c(
                        "a-table-column",
                        {
                          key: "returnNumber",
                          attrs: { "data-index": "returnNumber" }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_return_number")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "customerName",
                          attrs: { "data-index": "customerName", width: 200 }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_customer_name")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "returnDate",
                          attrs: { "data-index": "returnDate", width: 130 },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(returnDate) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("date")(returnDate)) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_date")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "returnTotal",
                          attrs: { "data-index": "returnTotal", width: 150 },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(record.returnTotal)
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_total_return")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "journalNumber",
                          attrs: { "data-index": "journalNumber", width: 180 },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "generaljournal.detail",
                                          params: { id: record.journalId }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(record.journalNumber || "-") +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_journal_number")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "status",
                          attrs: { "data-index": "status", width: 100 },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(record.status || "-") + " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_status")))]
                          )
                        ]
                      ),
                      _vm.hasPrivilegeCreate
                        ? _c(
                            "a-table-column",
                            {
                              key: "action",
                              attrs: {
                                width: 100,
                                align: "center",
                                fixed: "right"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(text, record) {
                                      return [
                                        _c(
                                          "a-dropdown",
                                          {
                                            attrs: {
                                              trigger: ["click"],
                                              placement: "bottomCenter"
                                            }
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "more" },
                                              on: {
                                                click: function(e) {
                                                  return e.preventDefault()
                                                }
                                              }
                                            }),
                                            _c(
                                              "a-menu",
                                              {
                                                attrs: { slot: "overlay" },
                                                slot: "overlay"
                                              },
                                              [
                                                record.status ===
                                                _vm.SALES_RETURN_STATUS.DRAFT
                                                  ? _c(
                                                      "a-menu-item",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.goToUpdate(
                                                              record.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t("lbl_edit")
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "a-menu-item",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.goToDetail(
                                                              record.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t("lbl_view")
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2577145891
                              )
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(_vm.$t("lbl_action")))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex", align: "middle" } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 18 } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.dtListCustomerReturn.totalElements,
                      "page-size-set": _vm.queryParams.limit,
                      "id-pagination": "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 6, align: "end" } },
                [
                  _c("a-tag", { attrs: { color: "grey" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_total_found")) + " : 0 ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "download",
                            type: "primary",
                            loading: _vm.loading.download,
                            disabled: !_vm.dtListCustomerReturn.totalElements
                          },
                          on: { click: _vm.handleDownload }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "printer",
                            type: "primary",
                            disabled: !_vm.dtListCustomerReturn.totalElements,
                            loading: _vm.loading.print
                          },
                          on: { click: _vm.handlePrint }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }