import { Api } from "@endpoint";
import { RequestSalesReturnCreate, RequestSalesReturnUpdate, ResponseDetailSalesReturn, ResponseListSalesReturn } from "@interface/sales-return.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class SalesReturnServices extends HttpClient {
  constructor() {
    super();
  }

  getListSalesReturn(params: RequestQueryParamsModel): Promise<ResponseListSalesReturn> {
    return this.get<ResponseListSalesReturn>(Api.SalesReturn , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  downloadReport(params: RequestQueryParamsModel) :Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.SalesReturnDownload, { params,  responseType: "arraybuffer" })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  createSalesReturn(payload: RequestSalesReturnCreate): Promise<ResponseDetailSalesReturn> {
    return this.post<ResponseDetailSalesReturn, RequestSalesReturnCreate>(Api.SalesReturn, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getDetailSalesReturn(id: string): Promise<ResponseDetailSalesReturn> {
    return this.get<ResponseDetailSalesReturn>(`${Api.SalesReturn}/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  updateSalesReturn(id: string, payload: RequestSalesReturnUpdate): Promise<ResponseDetailSalesReturn> {
    return this.put<ResponseDetailSalesReturn, RequestSalesReturnUpdate>(`${Api.SalesReturn}/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  cancelSalesReturn(id: string): Promise<void> {
    return this.put<void, string>(`${Api.SalesReturn}/cancel/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  submitSalesReturn(id: string): Promise<boolean> {
    return this.put<boolean, string>(`${Api.SalesReturn}/submit/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
}

export const salesReturnServices = new SalesReturnServices();
